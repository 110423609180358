<template>
  <v-footer id="home-footer" color="black" dark min-height="72">
    <v-container>
      <v-row>
        <v-col class="text-center text-md-right" cols="22" md="6">
          {{ getFooterName() }}
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { envx } from '@/environments/EnvX.ts'
export default {
  name: 'HomeFooter',
  methods: {
    getFooterName: function () {
      return 'Copyright © 2021-2023 ProInsight.com, ' + envx.webservername + '.'
    }
  }

  // data: () => ({
  //   social: [
  //     'Facebook',
  //     'Twitter',
  //     'Instagram',
  //     'Linkedin',
  //   ],
  // }),
}
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
